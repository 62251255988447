@import "~@angular/material/theming";
@include mat-core();

// $font-family: 'Source Sans Pro'
$custom-typography: mat-typography-config(  
  $font-family: Inter
);

@include angular-material-typography($custom-typography);


$app-light-primary: mat-palette($mat-blue, 800, 300, 900);
$app-light-accent: mat-palette($mat-green, 500, 300, 700);
$app-light-warn: mat-palette($mat-red);

$light-primary: mat-color($app-light-primary);
$light-accent: mat-color($app-light-accent);
$light-warn: mat-color($app-light-warn);

$app-light-theme: mat-light-theme($app-light-primary, $app-light-accent, $app-light-warn);

.app-light-theme {
  @include angular-material-theme($app-light-theme);

  .warn {
    background-color: mat-color($mat-yellow);
    color: #fff;
  }

  .success {
    background-color: mat-color($app-light-accent);
    color: #fff;
  }

  .mat-drawer {
    .mat-drawer-inner-container mat-toolbar {
      background: mat-color($app-light-primary) !important;
    }
  }

  //RadioButton Circle style
  .mat-radio-outer-circle {
    border: 1px solid #868e96;
    box-sizing: border-box;
    border-radius: 100px;
  }

  // radio button outor circle issue
  .mat-radio-button .mat-radio-ripple {
    height: 0px !important;
    width: 0px !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #1e4ed8;
  }

  ::ng-deep .app-light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #1e4ed8 !important;
  }

  ::ng-deep .mat-slide-toggle-bar {
    background: rgba(30, 78, 216, 0.2) !important;
    height: 16px !important;
  }

  ::ng-deep .app-light-theme .mat-slide-toggle-thumb {
    background-color: white !important;
  }

  //stepper
  ::ng-deep {
    .mat-step-icon {
      border-radius: 0% !important;
      color: white;
    }

    .app-light-theme .mat-step-header .mat-step-icon-selected,
    .app-light-theme .mat-step-header .mat-step-icon-state-done,
    .app-light-theme .mat-step-header .mat-step-icon-state-edit {
      background-color: #4caf50 !important;
      color: white;
    }

    .mat-tab-label, .mat-tab-link{
      font-size: 13px !important;
      font-weight: 700 !important;
    }
  }

  //Checkbox style
  .mat-checkbox-inner-container {
    width: 19px !important;
    height: 19px !important;
  }

  .mat-checkbox-frame {
    border: 1px solid #868e96 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #1e4ed8;
  }

  .mat-drawer-content {
    background-color: #ebeef1;
  }

  .background-primary {
    background: mat-color($app-light-primary);
    color: #fff;
  }

  .background-accent {
    background: mat-color($app-light-accent);
    color: #fff;
  }

  .background-dark {
    background: #bfbbbb;
    color: #fff;
  }

  button.text-white.mat-flat-button.mat-accent {
    span {
      color: #fff;
    }
  }

  .mat-form-field {
    width: 100%;
    margin-top: 16px;
  }

  .mat-tooltip {
    white-space: pre-line;
    font-size: 11px;
  }

  //Custom -ToolTip
  .custom-tooltip {
    background-color:#FFF;
    height: auto;
    color: #f44336 !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 13px;
    padding: 8px;    
    text-align: center;
    box-shadow: 0 1px 3px 0 rgb(28 28 28 / 35%);
  }

  .main-card {
    border-radius: 0;
    border-top: 3px solid mat-color($app-light-primary);
  }
  .side-nav {
    .mat-icon {
      font-size: 15px;
      height: 25px;
      width: 25px;
      &[svgIcon="dollar"] {
        width: 32px;
        height: 32px;
        margin-left: -6px;
      }
    }
  }

  .toast-info {
    background-color: #1e4ed8 !important;
    color: white !important;
  }
}

//Common styles
html,
body {
  height: 100%;
}

body {
  font-family: 'Inter';
// font-family: 'Source Sans Pro';
  margin: 0;
  background-color: #ebeef1;
  font-size: 13px;
}

.container {
  // padding: 16px;
}

.spacer {
  flex: 1 1 auto;
}

.mat-sort-header-arrow {
  left: -43px;
  font-size: medium;
}

.app-light-theme {
  //styles which are common for grid/forms should be placed here with mat classes
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1e4ed8;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1e4ed8;
  }

  .userNameCircle {
    color: white;
    font-weight: 400;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
  }

  .bank-account {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
  }

  // Upper Customer Details Section CSS
  .page-header-userNameCircle {
    height: 50px;
    width: 50px;
    line-height: 50px;
    margin-left: 12%;
    background-color: #627290;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    text-align: center;
    color: white;
    display: inline-block;
  }
  
  .page-header-customer-details {
    border: none;
    margin: 16px 16px 24px 16px;
  }
  
  .page-header-customerName {
    font-size: 20px;
    color: #121828;
    font-weight: 600;
  }
  
  .page-header-customerEmail {
    font-size: 13px;
    font-weight: 500;
    color: #101827;
    margin-top: 8px;
  }
  .page-header-customerEmailChip {
    font-size: 13px;
    font-weight: 500;
    color: #101827;
  }
  
  .mat-chip.mat-standard-chip {
    background-color: #d8dbe1; 
  }
  
  .backHeader {
    font-weight: 500;
    font-size: 13px;
    color: #1e4ed8;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
    padding: 16px 20px;
  }

  /* Page Header Common Styles*/
  .page-header {
    padding: 1% 0% 1% 2%;
    background-color: #F9FAFC;
  }
  
  .page-header-bottom {
    -webkit-box-shadow: 0 4px 4px -4px #F9FAFC;
    -moz-box-shadow: 0 4px 4px -4px #F9FAFC;
    box-shadow: 0 4px 4px -4px #F9FAFC; 
    padding-bottom: 6px;
    padding-right: 16px;
    background-color: #F9FAFC;
  }
  
  .add-page-header-spacing {
    padding: 0px 5px 10px 20px;
  }

  .page-header-text {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #101827;
  }
  
  .page-sub-header-text-1 {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #6e798f;
    margin-top: 2%;
  }
    
  .page-header-button-style {
    width: auto;
    border: 1px solid #1e4ed8 !important;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    background-color: #1e4ed8;
    line-height: 30px;
    color: white;
  }

  .white-button-style{
    width: auto;
    border: 1px solid #1e4ed8 !important;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    background-color: white;
    line-height: 30px;
  }

  /* Add Page Common Styles*/
  .create-now {
    padding: 10px 20px;
    font-weight: 600;
    font-size: 17px;
    line-height: 34px;
    color: #101827;
  }

  .stepper-header-style {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #101827;
  }
  
  .inner-stepper-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #101827;
  }
  
  .stepper-header-label{
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #101827;
  }
  
  .add-page-radio-button{
    width: 100px; 
    font-size: 12px;
  }

  
  .required:after {
    content: " *";
    color: red;
  }

  // Left Advanced Filter Styles
  .apply-filter-mat-drawer-height {
    width: 288px;
    height: 100%;
    z-index: 1000;
    position: fixed;
    padding-top: 30px;
    overflow: auto;
  }
  .mat-drawer-width-for-link {
    width: 500px !important;
  }
  
  .apply-filter-header {
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    color: #101827;
  }
  
  .apply-filter{
    padding: 12% 10% 2% 5%;
  }
  
  .apply-filter-body{
    padding: 0% 5% 5% 5%;
  }

  .custome-class {
    direction: ltr;
    .timepicker__header {    
      background-color: #1e4ed8 !important;  
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
    .timepicker-dial__control {
      font-size: 40px !important;
    }
  
    .timepicker-dial {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      justify-content: center;
      height: 44px;
    }

    .timepicker-button{
      color: #1e4ed8 !important; 
    }
}

.set-monospace-font {
  font-family: monospace !important;
  font-size: 15px !important;
}

}

